@import "../../values.scss";

.posting-person-card {
  position: relative;
  &.twr-card {
    .number {
      background-color: $danger;
    }
  }

  &.selected {
    border: 2px solid #26354D;
  }
  .card-body {
    display: flex;
    .content {
      flex: 1;
    }
  }
  .number {
    position: absolute;
    border-radius: 50px;
    font-size: 20px;
    font-weight: bold;
    background-color: black;
    border: 1px solid $lightgray;
    color: white;
    z-index: 1;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    top: -17px;
    left: -16px;
  }
  .play-button {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    z-index: 3;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    top: -10px;
    right: -8px;
    svg {
      fill: black;
      cursor: pointer;
      background-color: white;
      padding: 2px;
      border-radius: 50px;
    }
  }
}

.person-card-v {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  .comments-list {
    max-height: 40vh;
    overflow: auto;
  }

  .person-info{
    padding: 16px;
    background: #26354D1F;
    border-radius: 12px;
    margin-bottom: 24px;
    min-width: 350px;
    width: 100%;
    justify-content: space-between;

    .contact-button{
      border: 1px solid #26354D1F;
      padding: 4px 8px;
      border-radius: 4px;
      &:hover{
        background-color: #d8d8d81f;
      }
    }
    @media only screen and (max-width: 1440px) {
      min-width: 0;
      flex-wrap: wrap;
    }
  }
  .feedback-container{
    width: 100%;

    .feedback-wrapper{
      gap: 6px;
    }

    .fb-item {
      padding: 8px 12px;
      border: 1px solid #26354D1F;
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      span {
        text-transform: capitalize;
      }
    
      &.active {
        border: 1px solid $danger;
      }
    
      &:hover {
        background-color: #ececec;
      }
      @media only screen and (max-width: 1600px) {
        padding: 2px 4px;
        .text-span{
          display: none;
        }
      }
      
    }

    .video-review-comments{
      background-color: #f5f5f5;
      padding: 16px 16px 8px 16px;
      border-radius: 8px;
    }
    .comments-wrapper{
      .participantAvatar {
        .participantAvatar {
            background: #2B6ED3 !important;
            color: white !important;
        }
      }
    }
    .comments-wrapper, .video-review-comments{
      
      .note-container{
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 200px;
        padding: 12px 8px;
        border-radius: 8px;
        background-color: #F6F7F8;
  
        .note-item{
            gap: 4px;
            margin-bottom: 16px;
            position: relative;
            .trash-btn{
              opacity: 0;
              margin-left: auto;
              transition: all 0.2s;
              outline: none;
              &:focus{
                box-shadow: none;
              }
              &:disabled{
                cursor: not-allowed;
              }
            }
            &:hover{
              .trash-btn{
                opacity: 1;
              }
            }
        }
  
        .comment-author{
  
            .time{
                line-height: 12px;
            }
        }
        &::-webkit-scrollbar{
            width: 0.4rem;
        }
        &::-webkit-scrollbar-track{
            background:#e0e0e0;
        }
        &::-webkit-scrollbar-thumb{
            background: #a4a5a5;
        }
      }
      @supports (scrollbar-color:#e0e0e0 #bebebe){
        .note-container{
            scrollbar-color:  #e0e0e0 #bebebe;
            
        }
      }
      .comment-textarea{
        border: 1px solid #26354D33;
  
        &::placeholder{
            color: #26354D66;
        }
    }
      
    }
    .note-item{
      gap: 4px;
      margin-bottom: 16px;
    }
  }
}

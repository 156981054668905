.timeframe-modal {

    .modal-content {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.12);
    
        .modal-footer{
            padding: 16px 20px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap: 8px;

            >span{
                letter-spacing: 0.24px; 
            }

            >button{
                width: 250px;
            }
        }
    }
}

.two-input-row {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

input {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;

    &::placeholder {
        color: rgba(34, 54, 79, 0.40);

    }
}

.single-input-row,
.two-input-row {
    margin-bottom: 20px;

    input {
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
        color: #22364F;

        &::placeholder {
            color: rgba(34, 54, 79, 0.40);
        }
    }
}

.roles-container{
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.role-input-row {
    display: flex;
    align-items: center;
    gap: 8px;

    .role-name-input {
        width: 83%;
    }

    .role-number-input {
        width: 17%;
    }

    .minus-button {
        background: none;
        border: none;
        cursor: pointer;
    }
    .disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }

}

.add-role-button {
    margin-top: 12px;
    background: none;
    border: none;
    cursor: pointer;
    color: #22364F;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    text-decoration-line: underline;
}

.posting-link-page {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px 32px 120px;
  max-width: 1140px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 60px);

  .posting-page-item-container{
    display: flex;
    flex-direction: column;
    gap: 10px;

  }

  .posting-page-item{
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #26354D1F;
    border-radius: 12px;
  }

}
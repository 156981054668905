@import "../../../../values.scss";

.day-item {
  position: relative;
  padding: 16px 20px;
  border: 1px solid rgba(34, 54, 79, 0.12);
  border-radius: 12px;
  background: #FFF;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .first-row {
    color: #22364F;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.28px;
  }

  .second-row {
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgba(34, 54, 79, 0.64);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-left: auto;
  }

  .invisible-button{
    display: flex;
    flex-grow: 1;
    background: none;
    height:70px;
    border: none;
    outline: none !important;
  }

  .open-schedule-button {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid rgba(34, 54, 79, 0.12);
    color: #22364F;
    font-size: 14px;
    font-weight: 590;
    cursor: pointer;
    background: none;
    white-space: nowrap;
  }
  .round-button{
    border: none;
    background: none;
  }

  .bottom {
    height: 0;
    opacity: 0;
    margin-top: 20px;

    .bottom-rows {
      display: flex;
      align-items: center;
      gap: 20px;

      >* {
        flex: 1;
      }
    }

    .input-item {
      height: 35px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      border: 1px solid rgba(34, 54, 79, 0.20);
      border-radius: 4px;

      color: #22364F;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.28px;
      line-height: normal;
    }

    .day-item-btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;




    }
  }

  .show {
    height: auto;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  @media screen and (max-width: $desktop) {
    position: relative;
    .top {
      align-items: flex-end;
      .right{
        margin-left: unset;
        

        .round-button{
          position: absolute;
          right: 10px;
          top: 10px;
          width: 70%;
          text-align: right;
        }
      }

      .left{
        flex: 1;
        .second-row{
          flex-wrap: wrap;
          >span {
            flex: 1;
            white-space: nowrap;
          }
        }
      }  
    }

  }

  @media  screen and (max-width: $tablet){

    .top{
      
      .right{
        flex-wrap: wrap;
  
        .round-button{
          width: 50%;
        }
      }
    }

    .open-schedule-button{
      flex: 1;
    }
    .day-item-btn-container{
      >button{
        flex: 1;
      }
    }
  }
}
.talent-info-modal {

    .modal-lg{
        max-width: min(1000px, 95%);
    }
    .modal-sm{
        max-width: min(800px, 95%);
    }

    .modal-content {
        border-radius: 12px;

        .modal-header {
            align-items: center;
            padding: 12px 20px;

            .close-button {
                background: none;
                border: none;
                margin-left: 12px;
            }
            .role{
                text-transform: capitalize;
            }
        }

        .modal-body {
            display: flex;
            padding: 0;
            
            .modal-left {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 16px 20px;
                
                
                .talent-avatar {
                    width: 300px;
                    height: 300px;
                    border-radius:4px;
                    background-color: #D9D9D9;
                    object-fit: contain;
                }

                .talent-info-top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
    
                    .top-right{
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                    }
    
                }
                
                .detail-container, .size-wrapper{
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    padding: 8px;
                    border-radius: 8px;
                    background-color: #26354D0A;
                }
                
                .size-container-grid{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 8px;
                }

                .talent-info-bottom{
                    display: grid;
                    grid-template-areas:
                        'socials socials height '
                        'agent email phone';
                         
                    gap: 8px;
                }

                .social-link{
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }

                .item1 {
                    grid-area: socials;
                }
    
                .item2 {
                    grid-area: height;
                }
    
                .item3 {
                    grid-area: agent;
                }
    
                .item4 {
                    grid-area: email;
                }
    
                .item5 {
                    grid-area: phone;
                }

                .media-container{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .image-container{
                        display: flex;
                        gap: 8px;
                        overflow-y: hidden;
                        overflow-x: auto;
                    }

                    .headshot{
                        width: 100px;
                        height: 100px;
                        border-radius: 4px;
                        background-color: #D9D9D9;
                    }
                    .video{
                        width: 110px;
                        height: 145px;
                        border-radius: 4px;
                        background-color: #D9D9D9;
                    
                    }
                }
            }
            .modal-right{
                width: 33%;
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding: 16px 20px;
                border-left: 1px solid #26354D1F;

                .participantAvatar {
                    .participantAvatar {
                        background: #0070DA !important;
                        color: white !important;
                    }
                }

                .note-container{
                    flex: 1;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 300px;
                    padding: 12px 8px;
                    border-radius: 8px;
                    background-color: #F6F7F8;

                    .horizontal-line{
                        height: 1px;
                        background-color: #00000014;
                        position: relative;
                        margin-block: 8px;

                        .date-indicator{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background-color: #F6F7F8;
                            padding-inline: 8px;
                        }
                    }

                    .note-item{
                        gap: 4px;
                        margin-bottom: 16px;
                    }

                    .comment-author{

                        .time{
                            line-height: 12px;
                        }
                    }
                    &::-webkit-scrollbar{
                        width: 0.4rem;
                    }
                    &::-webkit-scrollbar-track{
                        background:#e0e0e0;
                    }
                    &::-webkit-scrollbar-thumb{
                        background: #a4a5a5;
                    }
                }
                @supports (scrollbar-color:#e0e0e0 #bebebe){
                    .note-container{
                        scrollbar-color:  #e0e0e0 #bebebe;
                        
                    }
                }

                .comment-textarea{
                    border: 1px solid #26354D33;

                    &::placeholder{
                        color: #26354D66;
                    }
                }
            }


            
        }
        .modal-footer{
            border-top: 1px solid #26354D1F;
        }
    }
}

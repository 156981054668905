@import "../../values.scss";

.customize-drop-menu{
  min-width: 290px !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 4px 0px hsla(0, 0%, 0%, 0.25);
  border: 1px solid hsla(217, 34%, 23%, 0.12) !important;

  .yes-picks-container{
    background: hsla(217, 34%, 23%, 0.1);
    .disabled-container{
      background: hsla(41, 99%, 47%, 0.2);
      padding: 6px 8px;
      border-radius: 10px;
      margin-left: 18px;
    }
  }
}
.video-row {
  display: flex;
  margin: 0 auto;
}

.react-player-wrap{
  border-radius: 12px;
  border: 1px solid #26354D1F;
  display: flex;
  gap: 16px;
  position: relative;
 
  .video-close-button{
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    cursor: pointer;
    border: none;
    background: none;
    border-radius: 50%;
  }
}

.size-cards {
  display: inline-flex;
  flex-direction: column;
  padding: 10px 20px;
  margin: auto;
  height: calc(100vh - 275px);
  overflow-y: auto;
  position: relative;

  .person-card {
    width: 300px;
    margin: 10px;
    border-radius: 10px;
    .avatar {
      width: 100%;
      height: 150px;
      object-fit: contain;
    }
  }

  .feedback-item {
    padding: 0 5px;
    border: 1px solid transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      margin-left: 5px;
    }
    &.active {
      border: 1px solid $danger;
    }
    &:hover {
      border: 1px solid $primary;
    }
  }
}

.files-wrapper {
  position: absolute;
  right: 10px;
}

.session-files-div {
  position: fixed;
  top: 10px;
  right: 450px;
}

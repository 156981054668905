.auth-header {
    .top-menu {
        display: flex;
        align-items: center;
    }
    .btn{
        color: #666;
    }
    .top-menu-mobile {
        display: none;
    }

    @media(max-width:700px) {
        .book-button,
        .top-menu {
            display: none;
        }
        .top-menu-mobile {
            display: block;
        }
    }
}


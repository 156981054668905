.time-frames {
    overflow: auto;

    .time-frame-header {
        display: flex;
        align-items: center;
        gap: 8px;

        .header-btn-container {
            position: relative;

            .dropdown-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                position: absolute;
                right: 8px;
                top: 7px;

                .dropdown-toggle {
                    background: none;
                    border: none;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    border-radius: 50%;

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    border-radius: 12px;
                    border: 1px solid rgba(34, 54, 79, 0.12);
                    background: #FFF;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }

                .dropdown-item {
                    font-size: 14px;
                    padding: 8px 12px;
                    font-weight: 500;
                    color: #22364F;
                    &:active{
                        background-color: #f8f9fa;
                    }
                }
            }
        }

        .time-frame-header-btn {
            min-width: 150px;
            padding: 8px 12px;
            background: none;
            border: 1px solid #22364F;
            border-radius: 18px;
            cursor: pointer;
        }
        .pr-32{
            padding-right: 32px;
        }

        .more-btn {
            background: rgba(34, 54, 79, 0.20);
            border-radius: 50%;
            padding: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .more-selected {
            background: rgba(255, 255, 255, 0.20);
        }

        .border-dash {
            border-style: dashed;
            padding: 8px 12px;
        }

        .selected {
            background: #22364F;
            color: white;
        }
    }

    .time-frame-content {
        margin-top: 20px;
        display: flex;
        gap: 16px;
        padding-bottom: 25px;

        .time-frame-item {
            max-width: 200px;
            min-width: 190px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .time-slot-container {
                display: flex;
                align-items: center;
                gap: 8px;

                .horizontal-line {
                    flex: 1;
                    height: 1px;
                    background: rgba(34, 54, 79, 0.12);
                }
            }

            .time-frame-content-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                margin-bottom: 8px;

                .role-count-container {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    padding: 2px 4px;
                    border-radius: 4px;
                    background: rgba(34, 54, 79, 0.12);
                }
            }

            .time-frame-content-body {
                padding: 8px;
                min-height: 37px;
                display: flex;
                flex-direction: column;
                gap: 8px;
                border-radius: 8px;
                background: rgba(34, 54, 79, 0.04);
            }

            .participant-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                padding: 8px;
                border-radius: 8px;
                border: 1px solid rgba(34, 54, 79, 0.08);
                background: #FFF;
                box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

                .participant-item-left {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    >.participantAvatar {
                        .participantAvatar {
                            background: #0070DA !important;
                            color: white !important;
                        }
                    }

                    .participantAvatar{
                        width: 50px;
                        height: 50px;
                        border-radius: 8px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
.confirm-modal, .confirmed-modal {

    .modal-content {
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.12);

        .modal-footer {
            padding: 16px 20px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap: 8px;

            >span {
                letter-spacing: 0.24px;
            }

            >button {
                width: 250px;
            }
        }
    }
}

.confirmed-modal{
    .modal-content{
        max-width: 400px;

        .modal-header{
            border-bottom: none !important;
        }
        .modal-body{
            padding: 0 20px 16px 20px !important;
        }
    }
}

.topic{
    display: flex;
    align-items: center;
    gap: 8px;
}

.content-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    .detail-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

.body-text{
    line-height: 21px;
}


.btn-container{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    
    .confirm-btn {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: #22364F;
        cursor: pointer;
    }
    .cancel-btn {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: none;
        border: 1px solid rgba(34, 54, 79, 0.12);
        cursor: pointer;
        color:#22364F;
    }
}

@import "../../values.scss";

.login-page {
  height: 100vh;
  overflow-y: auto;

  .register-pane {
    padding-top: 70px;
    max-width: 500px;
  }

  .header {
    position: sticky;
    top: 0;
    min-height: 70px;
    z-index: 1;
  }

  .login-form-wrapper {
    flex: 1;
    padding-bottom: 7%;
    min-width: 320px;
  }

  .btn {
    min-height: 38px;
  }

  .google-btn {
    min-height: 45px;
  }

  label {
    font-weight: bold;
  }

  .description-text {
    font-size: 1rem;
  }

  #login-alert {
    max-width: 350px;

    a {
      cursor: pointer;
      margin-left: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .user-type-item {
    cursor: pointer;

    * {
      cursor: pointer;
    }

    padding: 20px 25px;
    margin: 10px 0;
    box-shadow: 5px 6px;
    transition: .2s;
    background-color: white;
    border-radius: 7px;

    &:hover {
      box-shadow: 2px 2px;
      background-color: white;
    }
  }

  .avatar-choose {
    width: 100%;
    max-width: 450px;
    position: relative;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .photos-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      width: 100%;
      margin-bottom: 16px;

      .photo-item-wrap {
        position: relative;
        width: 40%;
        height: 150px;
        border-radius: 8px;
        border: 1px solid $danger;
        margin-bottom: 0;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .remove-photo-item {
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: white;
        border-radius: 20px;
        padding: 2px;
        cursor: pointer;
      }

      .photo-item {
        object-fit: contain;
        margin-bottom: 0;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
    }

    .camera-wrapper,
    img {
      max-width: 100%;
      max-height: 300px;
      border-radius: 20px;
      margin-bottom: 10px;
    }
  }

  &.talent-onboard {
    .login-form-wrapper {
      max-width: 1000px;
    }
  }


  .auth-wrap {
    flex: 1;
    max-width: 370px;
    width: 100%;
  }

  .auth-user-type-item {
    cursor: pointer;
    transition: .2s;
    background-color: white;
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 8px 12px;
    border: 1px solid lightgray;

    &.selected,
    &:hover {
      border: 1px solid black;
    }
  }

  .auth-btn {
    position: relative;
    padding: 12px;
    border: none;
    // background: white;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 6px;

    .auth-btn-icon {
      position: absolute;
      left: 12px;
    }
  }
}
@import "./values.scss";
@import "node_modules/bootstrap/scss/bootstrap";

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: .2s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1230;
  color: white;
}

.loading.show {
  opacity: 1;
}

.loading.show button {
  pointer-events: all;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
}

.list-group {
  >div:nth-child(odd) {
    background-color: $lightgray;
  }

  &.hover-highlight>div:hover {
    background-color: $gray;
  }
}

.action-wrap {
  >* {
    cursor: pointer;
  }
}

.pagination li {
  cursor: pointer;

  select {
    min-width: 30px;
    outline: none !important;
    text-align: center;
  };
  .page-count {
    min-width: 30px;
    text-align: center;
    display: inline-block;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.heyjoe-logo {
  height: 38px;
  padding-right: 20px;
  margin-right: 20px;

  &.white {
    filter: brightness(1000);
  }

  &.br {
    border-right: 1px solid $primary;
  }
}

.page-content {
  overflow-y: auto;
  max-height: calc(100vh - #{$headerheight});
  max-width: 1440px;
  margin-inline: auto;
  padding: 16px 32px
}

.small-avatar {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 10px;
}

.myfeedback-icon {
  position: relative;

  svg {
    left: -42px;
    width: 42px;
    height: 42px;
  }
}

.feedback-item {
  padding: 0 5px;
  border: 1px solid transparent;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 5px;
  }

  &.active {
    border: 1px solid $danger;
  }

  &:hover {
    border: 1px solid $primary;
  }
}

.feedback-modal {
  .feedback-item {
    font-size: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.uploading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}


.dropdown-toggle::after {
  margin-left: 0.655em;
  vertical-align: 0.055em;
}

.print-only,
.print-flex {
  display: none;
}

.app-footer {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  font-size: 0.7rem;
}

.spinning {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.notification-modal .modal-dialog {
  margin-left: auto;
  margin-right: 20px;
}

.notification-modal .notification-content {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media print {
  body {
    overflow-y: auto;
    page-break-inside: avoid;
  }

  .no-print {
    display: none !important;
  }

  ;

  .print-only {
    display: block !important;
  }

  .size-cards {
    display: block;
  }

  .person-card {
    display: inline-block;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .avoid-break {
    position: relative;
    break-inside: avoid !important;
  }
}

.ws-indicator {
  position: fixed;
  top: 40px;
  right: 10px;
  color: white;
}

.fullscreen-modal {
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: none;

  .modal-content {
    height: 100%;
  }
}

.react-datetime-picker {
  z-index: 2;

  .react-datetime-picker__wrapper {
    border: none;
    min-width: 280px;
  }
}

.page {
  height: calc(100vh - 60px);
  overflow: auto;
}

.send-email-container {
  overflow: hidden;

  .tox-tinymce {
    border: none;
    border-top: 1px solid #dee2e6 !important;
  }
}

.text-10 {
  font-size: 1rem;
}

.top-0 {
  top: 0px;
}

.transparent {
  opacity: 0;
}

.break-word {
  word-break: break-word;
}

.send-talent-email-modal {

  .modal-content{
    border-radius: 12px;
  }
  .recipient-list {
    height: 150px;
  }

  .input-cursor {
    border: none !important;
    outline: none !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: white !important;
      box-shadow: 0 0 0 30px white inset !important;
    }
  }

}

.remove-talent-modal {

  .modal-dialog{
    max-width: 600px;
  }

  .cancel-btn, .remove-btn{
    border-radius: 8px;
    border: none;
    padding: 8px 12px;
  }

  .remove-btn{
    background: #FF3F47;
    color: #FFF;
  }

  .cancel-btn{
    border: 1px solid rgba(34, 54, 79, 0.12);
    background: #FFF;
  }

}

.calendar-container .rbc-calendar {
  height: 75vh;
}

.invite-tab-content {
  min-height: calc(100vh - 200px);
}

.btn-w-md {
  width: 110px;
}

.text-blue {
  color: #3322ff;
}

.text-strikethrough {
  text-decoration: line-through;
}

.bread-crumb{
  cursor: pointer;
}

//datepicker styling
.timeInputWrap, .dateInputWrap {

  position: relative;

  .react-datepicker-wrapper {
      width: 100%;

      input {
          padding: 8px 12px;
          border: 1px solid rgba(34, 54, 79, 0.20);
          border-radius: 4px;
          width: 100%;
          outline: none !important;
          color: #22364F;

          &:focus {
              border-color: #4e70a3;
              outline: 0;
              box-shadow: 0 0 0 0.2rem rgba(37, 53, 77, 0.25);
          }
      }
  }

  .react-datepicker-popper {
      border-radius: 4px;
      border: 1px solid #DADDE0;
      background: #FFF;
      position: absolute;
      top: 10px !important;
      // left: -29px !important;

      .react-datepicker,
      .react-datepicker__time-container,
      .react-datepicker__time-box {
          text-align: left;
          width: 100%;
          border: none;
      }

      .react-datepicker {}

      .react-datepicker__time-list {
          margin-right: 8px !important;

          &::-webkit-scrollbar {
              margin-right: 8px;
              width: 0.6rem;
          }

          &::-webkit-scrollbar-track {
              margin-right: 8px !important;
              // margin-top: 2px;
              border-radius: 4px;
              background: #EDF1F5;
              height: 100%;
          }

          &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background: #B3B6BA;
          }
      }

      &[data-placement^="top"] {
          padding-bottom: 0rem;
      }

      &[data-placement^="bottom"] {
          padding-top: 0rem;
      }

      

      .react-datepicker__triangle {
          display: none;
      }

      .react-datepicker__time-list-item--selected {
          background-color: #e4e7eb !important;
          color: #22364F !important;
          // font: 500 1.3rem/1.8rem Inter !important;
      }

      .react-datepicker__time-list-item {
          // font: 500 1.3rem/1.8rem Inter;
          color: #22364F;
          display: flex;
          align-items: center;
          padding: 10px 14px !important;
          height: 38px !important;

          &:hover {
              background-color: #edf1f5 !important;
          }

          &:active {
              background-color: #e4e7eb !important;
          }
      }
  }
}
.timeInputWrap{
  .react-datepicker-popper {
    width: 150px;

    .react-datepicker__header {
      display: none;
    }
  }
}

//button styling
.primary-solid-button{
  padding: 8px 12px;
  background-color: #26354D;
  color: #FFF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;

  &:disabled{
    cursor: not-allowed;
    background-color: #DADDE0;
  }
}

.primary-outline-button{
  padding: 7px 12px;
  background-color: #FFF;
  color: #26354D;
  border: 1px solid #26354D1F;
  border-radius: 8px;
  cursor: pointer;

  &:disabled{
    cursor: not-allowed;
  }
}

// font styling //

.h-32-400 {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: .64px;
  color: #22364F;
}

.h-24-600{
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.02em;
  color: #26354D;
}
.h-24-400{
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02em;
  color: #26354D;
}

.h-22-600{
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.02em;
  color: #26354D;
}

.h-20-600 {
  color: #22364F;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}

.h-20-400 {
  color: #22364F;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}

.h-16-400 {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .08px;
  color: #22364F;

  &::placeholder {
    color: rgba(34, 54, 79, 0.24);

  }
}

.h-16-600 {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: .08px;
  color: #22364F;
}

.h-16-700 {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .08px;
  color: #FFF;
}

.h-14-400 {
  font-size: .875rem;
  font-weight: 400;
  line-height: 20px;
  color: #22364F; 
}
.h-14-600 {
  font-size: .875rem;
  font-weight: 600;
  line-height: normal;
  color: #22364F; 
}

.h-14-700 {
  font-size: .875rem;
  font-weight: 700;
  color: #FFF;
  line-height: normal;
}

.h-12-700 {
  color: #22364F;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
}

.h-12-600 {
  color: #22364F;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
}

.h-12-400 {
  color: #22364F;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 2%;
}
.h-10-600 {
  color: #22364F;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 2%;
}

.ls-2{
  letter-spacing: 0.02em;
}
.ls-5{
  letter-spacing: 0.05em;
}
.ls-16{
  letter-spacing: 0.16em;
}

.lh-150{
  line-height: 150%;
}

.primary-color{
  color: #26354D !important;
}

.secondary-color{
  color: rgba(34, 54, 79, 0.64); 
}

.text-white{
  color: #FFF;
}

.text-danger{
  color: #EE514F;
}

.text-muted{
  color: #26354DB8;
}

.text-green{
  color: green !important;
}

.underline{
  text-decoration: underline;

}

//border-radius//
.br-8 {
  border-radius: 8px;
}

.error-indicator{
  background-color: #ff7a7a;
  padding: 4px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
}
.checkbox-container{
  input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid #303030;
    outline: none;
    cursor: pointer;
  
    &.checked {
      background-color: #303030;
      position: relative;
    }
  }
  input.checked::before {
    content: "\2714";
    font-size: 14px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  input[type="checkbox"]:focus {
    box-shadow: 0 0 4px #303030;
  }
}

.session-action-bts{
  display: flex;
  align-items: center;
  gap: 4px;
}

.action-button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #fff;
  border: 1px solid #26354D1F;
  border-radius: 8px;
  padding: 8px 12px;
  white-space: nowrap;

  &:hover{
    background: rgb(244 244 244);
  }

  @media screen and (max-width: $desktop) {
    >span {
      display: none;
    }
  }
  @media screen and (max-width: $tablet) {
    padding: 8px;
  }
}

.custom-dropdown-btn{
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #26354D1F !important;
  border-radius: 8px !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 8px 12px !important;

  &:hover{
    background: rgb(244 244 244) !important;
  }
}

.danger-button{
  background: #fff;
  border: 1px solid #EE514F;
  border-radius: 8px;
  padding: 8px 12px;
  color: #EE514F !important;

  @media screen and (max-width: $tablet) {
    padding: 8px;
  }
}

.div-input{
  display: flex;
  border-radius: 4px;
  border: 1px solid #26354D33;
  overflow: hidden;
}

.height-input{
  width: 73px;
  padding: 8px 12px ;
  border: none;
  font-size: 14px;
  line-height: 16px;
  color: #26354D;
}
.indicator{
  background-color: #26354D14;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #26354DA3 !important;
}
.radio-container{

  .yes-no{
    display: flex;
    gap: 16px;
    align-items: center;

    >div{
      display: flex;
      align-items: center;
      gap: 4px;

      label{
        margin: 0;
      }
    }
  }
}

.index-indicator-white {
    position: absolute;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1em;
    background-color: #fff;
    border: 1px solid #26354D1F;
    color: #26354D;
    z-index: 1;
    text-align: center;
    padding: 0 4px;
    top: 16px;
    left: 16px;
}
.like-button, .like-button-sm{
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
}

.like-button{
  z-index: 100;
  width: 48px;
  height: 48px;
  inset: 10px 20px auto auto;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  background: hsla(0, 0%, 0%, 0.25);
  border-radius: 50%;
  backdrop-filter: blur(20px);
  box-shadow: -0.73px 0.73px 0.73px -1.46px hsla(0, 0%, 100%, 0.35) inset;
  outline: 1px solid hsla(0, 100%, 80%, 1);

  &.liked{
    background:hsla(0, 100%, 70%, 1);
  }
}
.like-button-sm{
  width: 26px;
  height: 26px;
  inset: 15px 15px auto auto;
  background:#fff;
  box-shadow: 0 1px 4px 0 hsla(240, 4%, 5%, 0.05);
  border-radius: 9px;

  &.liked{
    background:hsla(0, 100%, 70%, 1);
  }
}
.videos-select {
  display: flex;
  overflow-x: auto;
  max-width: 800px;
  .video-item {
    margin: 0 10px;
    max-width: 250px;
    cursor: pointer;
    padding: 3px;
    img {
      padding: 5px;
      max-height: 150px;
    }
    &.selected {
      img {
        box-shadow: 0 0 2px 1px #777;
      }
    }
  }
}
.record-videos-modal{
  position: relative;


  .videos-select{
    .video-item{
      position: relative;
    }
  }
  .active-video-container{
    max-height: 60vh;
    video{
      border-radius: 8px;
    }
  }

  .comment-count{
    height: 26px;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    position: absolute;
    top: 48px;
    right: 15px;
    background: #fff;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    border-radius: 9px;
    box-shadow: 0px 0px 16px 1px rgb(255 6 6 / 33%);
    border: 2px solid rgb(211 207 207 / 72%);
    z-index: 100;

    backdrop-filter: blur(20px);
  }
}

.tooltip-inner{
  background: white !important;
  position: relative;
  max-width: 350px !important;
  padding: 10px 40px 10px 20px !important;
  border: 1px solid hsla(196, 7%, 68%, 1);
  box-shadow: 0 4px 4px -1px hsla(240, 4%, 5%, 0.05);
  text-align: left !important;

  .close-button{
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    cursor: pointer;
    border: none;
    background: none;
    border-radius: 50%;
    padding: 0;
  }
}

.session-comments-container{
  .note-item{
    .session-trash-btn{
      display: none;
    }
    &:hover{
      .session-trash-btn{
        display: block;
      }
    }
  }
}

@media (max-width: 1360px) {
  .videos-select {
    max-width: 500px;
  }
}
@media (max-width: 1000px) {
  .videos-select {
    max-width: 350px;
  }
}
@import "../../values.scss";

.session-edit-page-wrapper{

  overflow-y: auto;
  height: calc(100vh - #{$headerheight});
  
  .session-edit-page-container {
    max-width: 1440px;
    margin-inline: auto;
    padding: 16px 32px;
  
    .edit-button{
      background: none;
      border: none;
      padding: 10px;
    }
  
    .input {
      width: 300px;
      display: block;
      padding: 4px 8px;
      border-radius: 4px;
      border: 1px solid #26354D33;
      color: #26354D;
    }
  
    .tab-header{
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #26354D1F;
  
      .tab-item{
        background: none;
        border: none;
        width: 135px;
        padding: 8px 0 0;
        cursor: pointer;
        text-align: center;
        
      }
      .horizontal-line{
        margin-top: 12px;
        width: 100%;
        height: 3px;
        background-color: transparent;
        border-radius: 6px 6px 0 0;
  
        &.active{
          background-color: #EE514F;
        }
      
      }
    }
  
    .day-item-container{
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  
    .role-item-container{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  
    .accordion > .card.opened {
      overflow: visible;
    }
  
    .right-button-panel{
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
  
      .session-btn-container{
        display: flex;
        align-items: center;
        gap: 4px;
      }
  
      .button-icon{
        background: none;
        border: none;
      }

      .button-panel-right{
        display: flex;
        gap: 4px;
      }

    }
  }
  @media screen and (max-width: $tablet){

    .session-edit-page-container {
      padding: 8px 8px 150px;

      .button-panel-right{
        flex: 1;

        >button{
          flex: 1;
        }
      }
    }

    .right-button-panel{
      margin-top: 16px;

      .session-btn-container{
        flex: 1;
  
        
        .action-button{
          flex: 1;
        }
      }
    }

    .bottom-rows{
      flex-wrap: wrap;
      .mw-150{
        min-width: 150px;
      }
      .input-item{
        white-space: nowrap;
      }
    }
    
  }
    
  
}

@import "../values.scss";

.global-header {
  height: $headerheight;
  padding-left: 40px !important;

  .header-container{
    width: 100%;
    justify-content: space-between;
  }
}

.header-logo {
  height: 50px;
  img {
    border-radius: 6px;
    height: 100%;
  }

}
@media  screen and (max-width: $tablet) {
  .global-header {
    padding-left: 10px !important;
  }
  .header-logo{
    height: 30px;
  } 
}
@media  screen and (max-width: $mobile) {
  
  .header-logo{
    height: 20px;
  } 
  .title-text{
    overflow: hidden;
    max-width: 125px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

#header-title {
  color: white;
  display: flex;
}

.fade-alert {
  animation: fade-alert 1s infinite;
}

@keyframes fade-alert {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.version-display {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
}

@import "../values.scss";

.global-footer {
  padding-bottom: 24px;
}


@media  screen and (max-width: $tablet) {
  .global-footer {
    padding-bottom: 150px;
  }
   
}

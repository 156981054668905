@import "../../../values.scss";

.schedule-container {
    height: calc(100vh - #{$headerheight});
    ;
    padding: 16px 32px;

    .schedule-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .share-button{
            padding: 8px 12px;
            background: none;
            border-radius: 8px;  
            border: 1px solid rgba(34, 54, 79, 0.12);           
        }
    }

    .empty-state-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        background-image: url('../../../assets/schedule-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: -40px;

        .empty-state-content {
            margin-bottom: 80px;
            max-width: 431px;
            padding: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            background: #fff;
            border-radius: 26px;
            border: 1px solid rgba(34, 54, 79, 0.12);
            box-shadow: 0px 0px 80px 20px #FFF; 

            .empty-state-title {
                color: #22364F;
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.1px;
            }

            .empty-state-description {
                text-align: center;
                color: rgba(34, 54, 79, 0.50);
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 0.07px;
            }
        }
    }
}
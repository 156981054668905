@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.group-videos {
  display: flex;
  overflow-x: scroll;
  margin-bottom: 15px;

  .group-like-button-sm{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;
    width: 26px;
    height: 26px;
    inset: 2px 2px auto auto;
    background:#fff;
    box-shadow: 0 1px 4px 0 hsla(240, 4%, 5%, 0.05);
    border-radius: 9px;
    cursor: pointer;
    &.liked{
      background:hsla(0, 100%, 70%, 1);
    }
  }

  .video-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;

    .index-indicator {
      position: absolute;
      left: 5px;
      top: 3px;
      z-index: 1;
      background: black;
      color: white;
      display: inline-block;
      min-width: 25px;
      height: 20px;
      border-radius: 10px;
      text-align: center;
      line-height: 19px;
      font-weight: bold;
      box-shadow: 0 0 3px 1px lightgray;
    }
    &.active{
      border: 2px solid #26354D;
    }
  }

  .dummy-player {
    min-width: 100px;
    width: 100px;
    height: 100px;
    object-fit: contain;
    object-position: center;
    margin: 5px;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;

    .dummy-player-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }

  .refresh-videos {
    position: absolute;
    cursor: pointer;
    right: 120px;
    z-index: 11;
    outline: none;
    border: none;
    background: rgba(255, 255, 255, 0.5);
    font-size: 18px;

    &.spin svg {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  .group-sorter {
    position: absolute;
    right: 118px;
    top: 25px;
    z-index: 11;

    .btn {
      background-color: rgba(255, 255, 255, 0.5);
      border: none;
    }

    .sort-icon {
      width: 20px;
    }
  }
}
.group-active-video-container{
  border-radius: 8px;

  >video{
    max-height: 240px;
  }
}
.group-video-close-button{
  width: 28px;
  height: 28px;
  position: absolute;
  top: -25px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.63);
  border-radius: 50%;
  padding: 0;
  border: none;
}
.mute-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
  z-index: 1000;
}

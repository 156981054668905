$body-bg: #ffffff;
$body-color: #111;

$lightgray:  #efefef;
$gray:  #e7e7e7;
$primary: #25354d;
$danger: #ee514f;

$theme-colors: (
  "primary": $primary,
  "lightgray": $lightgray,
  "danger": $danger
);

$headerheight: 60px;

//breakpoints
$mobile: 550px;
$tablet: 768px;
$desktop: 1200px;

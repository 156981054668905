.add-role-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .arrow-button {
    background: none;
    border: none;

    &:hover svg {
      fill: rgb(255, 248, 248);
    }
  }

  .role-item {
    padding: 13px 20px;
    border: 1px solid #26354D1F;
    border-radius: 12px;

    .input {
      width: 100%;
      display: block;
      padding: 8px 12px;
      border-radius: 4px;
      border: 1px solid #26354D33;
      font-size: 14px;
      line-height: 16px;
      color: #26354D;
    }

  }

  .add-role-item-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;



  

    .save-role-button {
      padding: 9px 12px;
      border-radius: 8px;
      background: #22364F;
      border: none;
      color: #fff;

      &:disabled {
        background: #26354D1F;
        color: #26354D;
        cursor: not-allowed;
      }
    }

    
  }
}

.remove-role-button {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #FF3F47;
  background: #FFF;
  cursor: pointer;
  color: #FF3F47 !important;
}

.cancel-button {
  padding: 9px 12px;
  border-radius: 8px;
  background: #FFF;
  border: 1px solid #26354D1F;
  color: #26354D;
}

.role-remove-modal {

  .modal-sm{
    max-width: min(400px, 50%);
    
}

  .modal-content {
    border-radius: 12px;
    border: none;
    
    .modal-body {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px 20px 25px;
      
      .close-button {
        background: none;
        border: none;
        margin-left: 12px;
      }
    }

    .modal-footer {
      border-top: 1px solid #26354D1F;
    }
  }
}